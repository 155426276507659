import { useHistory } from "react-router-dom";
import UserApi from "../api/User";
import JWT from "jwt-decode";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import $ from "jquery";

const Register = () => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastname] = useState("");
  const [mobileno, setMobileno] = useState("");
  const [city, setCity] = useState("");
  const history = useHistory();

  useEffect(() => {
    getMobileNumber();
  }, []);

  const getMobileNumber = async () => {
    try {
      const mobileNumber = await localStorage.getItem("mobileNumber");
      await setMobileno(mobileNumber);
      if (!mobileNumber) {
        window.location = "/";
      }
    } catch (error) {}
  };

  const handleRegister = async () => {
    if (firstname === "" || lastname === "" || mobileno === "" || city === "") {
      // return alert("Please enter all the detail.");
      Swal.fire({
        title: "Jama Udhar",
        text: "Please enter all the detail.",
        icon: "error",
        confirmButtonClass: "btn btn-primary",
        buttonsStyling: false,
      });
      return;
    } else if (mobileno.length < 10) {
      // return alert("Enter 10 digit mobile no.");
      Swal.fire({
        title: "Jama Udhar",
        text: "Enter 10 digit mobile no.",
        icon: "error",
        confirmButtonClass: "btn btn-primary",
        buttonsStyling: false,
      });
      return;
    }
    const user = {
      firstname,
      lastname,
      mobileno,
      city,
      notification_token: "",
      gst_no: "",
      pancard_no: "",
      submobileno: mobileno,
      TnC: "No",
    };

    const result = await UserApi.addUser(user);
    if (!result.ok) {
      return alert("Could not add user. Please try again");
    } else {
      try {
        if (result.data) {
          if (result.data.error) {
            return alert(result.data.error[0]);
          }
        }
        const data = JWT(result.data);
        await localStorage.setItem("user", JSON.stringify(data));
        history.push("/termNCondition", { type: "register" });
        // window.location = "/termNCondition";
      } catch (error) {
        alert("Please try again later! " + error);
      }
    }
  };

  $("#title").text("Register - JamaUdhar");

  return (
    <body
      className="vertical-layout vertical-menu-modern 1-column navbar-floating footer-static bg-full-screen-image blank-page blank-page"
      data-open="click"
      data-menu="vertical-menu-modern"
      data-col="1-column"
    >
      <div className="app-content content">
        <div className="content-overlay"></div>
        <div className="header-navbar-shadow"></div>
        <div className="content-wrapper">
          <div className="content-header row"></div>
          <div className="content-body">
            <section
              className="row flexbox-container"
              style={{ backgroundColor: "#00706c" }}
            >
              <div className="col-xl-8 col-10 d-flex justify-content-center">
                <div
                  className="card bg-authentication rounded-0 mb-0"
                  style={{
                    backgroundColor: "#00706c",
                  }}
                >
                  <div className="row m-0">
                    {/* <div className="col-lg-6 d-lg-block d-none text-center align-self-center pl-0 pr-3 py-0">
                      <img
                        src="../../../app-assets/images/pages/register.jpg"
                        alt="branding logo"
                      />
                    </div> */}
                    <div className="col-lg-12 col-12 p-0">
                      <div
                        className="card rounded-0 mb-0 p-2"
                        style={{
                          backgroundColor: "#00706c",
                        }}
                      >
                        <div
                          className="card-header pb-1"
                          style={{ justifyContent: "center" }}
                        >
                          <div className="card-title">
                            <img
                              src="../../../app-assets/images/logo/JU.png"
                              alt="png"
                              height="100"
                            />
                          </div>
                        </div>
                        <div
                          className="card-header pt-50 pb-1"
                          style={{ justifyContent: "center" }}
                        >
                          <div className="card-title">
                            <h4
                              className="mb-0"
                              style={{
                                color: "white",
                              }}
                            >
                              Register
                            </h4>
                          </div>
                        </div>
                        <p className="px-2"></p>
                        <div className="card-content">
                          <div className="card-body pt-0">
                            <form action="index.html">
                              <div className="form-label-group">
                                <input
                                  type="text"
                                  id="firstname"
                                  className="form-control"
                                  placeholder="First Name"
                                  onChange={(e) => setFirstName(e.target.value)}
                                  required
                                />
                                <label for="firstname">First Name</label>
                              </div>
                              <div className="form-label-group">
                                <input
                                  type="text"
                                  id="lastname"
                                  className="form-control"
                                  placeholder="Last Name"
                                  onChange={(e) => setLastname(e.target.value)}
                                  required
                                />
                                <label for="lastname">Last Name</label>
                              </div>
                              <div className="form-label-group">
                                <input
                                  type="text"
                                  id="mobileNumber"
                                  className="form-control"
                                  placeholder="Mobile Number"
                                  maxLength="10"
                                  value={mobileno}
                                  readOnly
                                  required
                                />
                                <label for="mobileNumber">Mobile Number</label>
                              </div>
                              <div className="form-label-group">
                                <input
                                  type="text"
                                  id="city"
                                  className="form-control"
                                  placeholder="City"
                                  onChange={(e) => setCity(e.target.value)}
                                  required
                                />
                                <label for="city">City</label>
                              </div>
                              {/* <div className="form-group row">
                                <div className="col-12">
                                  <fieldset className="checkbox">
                                    <div className="vs-checkbox-con vs-checkbox-primary">
                                      <input
                                        type="checkbox"
                                        defaultChecked={checked}
                                        onChange={() => setChecked(!checked)}
                                      />
                                      <span className="vs-checkbox">
                                        <span className="vs-checkbox--check">
                                          <i className="vs-icon feather icon-check"></i>
                                        </span>
                                      </span>
                                      <span
                                        className=""
                                        style={{ color: "white" }}
                                      >
                                        {" "}
                                        I accept the terms & conditions.
                                      </span>
                                    </div>
                                  </fieldset>
                                </div>
                              </div> */}
                              {/*<Link to="/">*/}
                              {/*  <button className="btn btn-outline-primary float-left btn-inline mb-50">*/}
                              {/*    Login*/}
                              {/*  </button>*/}
                              {/*</Link>*/}

                              <div
                                className="card-header pt-50 pb-1"
                                style={{ justifyContent: "center" }}
                              >
                                <div className="card-title">
                                  <h4 className="mb-0">
                                    <button
                                      type="button"
                                      className="btn float-right btn-inline mb-50"
                                      style={{
                                        backgroundColor: "white",
                                        color: "#00706c",
                                      }}
                                      onClick={() => handleRegister()}
                                    >
                                      <b>Register</b>
                                    </button>
                                  </h4>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
};

export default Register;
