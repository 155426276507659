import { useEffect, useState } from "react";
import SideBar from "./SideBar";

const Header = () => {
  const [user, setUser] = useState();

  useEffect(() => {
    const result = localStorage.getItem("user");
    const userDetail = JSON.parse(result);
    setUser(userDetail);
  }, []);

  const logoutUser = async () => {
    await localStorage.removeItem("user");
    window.location.replace("/");
  };

  return (
    <>
      <nav className="header-navbar navbar-expand-lg navbar navbar-with-menu floating-nav navbar-light navbar-shadow">
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div className="navbar-collapse" id="navbar-mobile">
              <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                <ul className="nav navbar-nav">
                  <li className="nav-item mobile-menu d-xl-none mr-auto">
                    <a className="nav-link nav-menu-main menu-toggle hidden-xs">
                      <i className="ficon feather icon-menu"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <ul className="nav navbar-nav float-right">
                <li className="dropdown dropdown-user nav-item">
                  <a
                    className="dropdown-toggle nav-link dropdown-user-link"
                    data-toggle="dropdown"
                  >
                    <div className="user-nav d-sm-flex d-none">
                      <span className="user-name text-bold-600">
                        {user && user.first_name + " " + user.last_name}
                      </span>
                      <span className="user-status"></span>
                    </div>

                    <span>
                      {/* <a onClick={() => logoutUser()}>
                        <i className="feather icon-power"></i>
                      </a> */}
                      <img
                        src="../../../app-assets/images/logo/JU.png"
                        alt="png"
                        height="40"
                        width="30"
                      />
                    </span>
                  </a>
                  {/* <div className="dropdown-menu dropdown-menu-right">
                   
                    <div className="dropdown-divider"></div>
                    <button
                      className="dropdown-item"
                      onClick={() => logoutUser()}
                    >
                      <i className="feather icon-power"></i> Logout
                    </button>
                  </div> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <SideBar />
    </>
  );
};

export default Header;
