import { useEffect, useState } from "react";
import Header from "./Header";
import UserApi from "../api/User";
import Swal from "sweetalert2";
import moment from "moment";
import $ from "jquery";
import { Link } from "react-router-dom";

const SubscriptionView = () => {
  const [subscriptionList, setSubscriptionList] = useState([]);

  useEffect(() => {
    userExistOrNot();
    getSubscriptionList();
  }, []);
  // console.log(subscriptionList);
  const userExistOrNot = async () => {
    try {
      const user = await localStorage.getItem("user");
      if (!user) {
        window.location = "/";
      }
    } catch (error) {}
  };

  const getSubscriptionList = async () => {
    const results = await localStorage.getItem("user");
    const userDetail = JSON.parse(results);

    const users = {
      mobile_no: userDetail.mobile_no,
      status: "all",
    };
    const result = await UserApi.getSubscriptionList(users);
    console.log(result);
    if (!result.ok) {
      Swal.fire({
        title: "Jama Udhar",
        text: "Something went wrong!Try again later",
        icon: "error",
        confirmButtonClass: "btn btn-primary",
        buttonsStyling: false,
      });
    } else {
      setSubscriptionList(result.data);
    }
  };

  // function convert(current_datetime) {
  //   let formatted_date =
  //     current_datetime.getUTCDate() +
  //     "-" +
  //     (current_datetime.getUTCMonth() + 1) +
  //     "-" +
  //     current_datetime.getUTCFullYear() +
  //     " ";
  //   return formatted_date;
  // }

  $("#title").text("All Membership - JamaUdhar");

  return (
    <>
      <Header />
      <div className="app-content content">
        <div className="content-overlay"></div>
        <div className="header-navbar-shadow"></div>
        <div className="content-wrapper">
          <div className="content-header row">
            <div className="content-header-left col-md-9 col-12 mb-2">
              <div className="row breadcrumbs-top">
                <div className="col-12">
                  <h2 className="content-header-title float-left mb-0">
                    Membership List
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="content-body">
            <section id="basic-examples">
              <div className="row match-height">
                {subscriptionList &&
                  subscriptionList.map((value, index) => (
                    <div className="col-xl-4 col-md-6 col-sm-12">
                      <div className="card">
                        <div className="card-content">
                          <div className="card-body">
                            <div
                              style={{
                                height: 100,
                                backgroundColor: "#00776C",
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                borderTopLeftRadius: 15,
                                borderTopRightRadius: 15,
                                flexDirection: "column",
                              }}
                            >
                              <h3 style={{ color: "white" }}>
                                {" "}
                                {value.first_name + " " + value.last_name}
                              </h3>
                              <h4 style={{ color: "white" }}>
                                {" "}
                                {value.plan_name}
                              </h4>
                              {/*<h4 style={{ color: "white" }}>*/}
                              {/*  {" "}*/}
                              {/*  {value.plan_price != 0 && "₹" + value.plan_price}*/}
                              {/*</h4>*/}
                            </div>
                            <h3
                              className="mt-1"
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                              }}
                            >
                              {/*&nbsp;*/}
                              {value.plan_price !== 0 && "₹" + value.plan_price}

                              {value.plan_price !== 0 && (
                                <Link
                                  to={{
                                    pathname: "/invoice",
                                    state: { data: value },
                                  }}
                                >
                                  <a
                                    title={"Invoice"}
                                    style={{
                                      float: "right",
                                    }}
                                  >
                                    {/*{value.receipt} <i className={"feather icon-file-text"}></i>*/}
                                    <i className={"feather icon-file-text"}></i>
                                  </a>
                                </Link>
                              )}
                            </h3>
                            <hr className="my-1" />
                            <div
                              className="d-flex justify-content-between mt-2"
                              style={{ paddingLeft: 20, paddingRight: 20 }}
                            >
                              <h5 style={{ color: "gray" }}>Receipt No.</h5>
                              <h4>{value.receipt}</h4>
                            </div>
                            <div
                              className="d-flex justify-content-between mt-2"
                              style={{ paddingLeft: 20, paddingRight: 20 }}
                            >
                              <h5 style={{ color: "gray" }}>Mobile Number</h5>
                              <h4>{value.sub_mobile_no}</h4>
                            </div>
                            <div
                              className="d-flex justify-content-between mt-2"
                              style={{ paddingLeft: 20, paddingRight: 20 }}
                            >
                              <h5 style={{ color: "gray" }}>Validity upto</h5>
                              <h4>{value.validity_days} days</h4>
                            </div>
                            <div
                              className="d-flex justify-content-between mt-2"
                              style={{ paddingLeft: 20, paddingRight: 20 }}
                            >
                              <h5 style={{ color: "gray" }}>Start Date</h5>
                              <h4>
                                {moment(value.start_date).format("DD-MM-YYYY")}
                              </h4>
                            </div>
                            <div
                              className="d-flex justify-content-between mt-2"
                              style={{ paddingLeft: 20, paddingRight: 20 }}
                            >
                              <h5 style={{ color: "gray" }}>End Date</h5>
                              <h4>
                                {moment(value.end_date).format("DD-MM-YYYY")}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </section>

            {/* <section id="basic-datatable">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Memberships</h4>
                    </div>
                    <div className="card-content">
                      <div className="card-body card-dashboard">
                        <p className="card-text"> </p>
                        <div className="table-responsive">
                          <table
                            id="datatable"
                            className="table zero-configuration"
                          >
                            <thead>
                              <tr>
                                <th>Plan Name</th>
                                <th>Plan Description</th>
                                <th>Price</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {subscriptionList &&
                                subscriptionList.map((uri, index) => (
                                  <tr>
                                    <td>{uri.plan_name}</td>
                                    <td>{uri.plan_description}</td>
                                    <td>{uri.plan_price}</td>
                                    <td>
                                      {moment(uri.start_date).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                    <td>
                                      {moment(uri.end_date).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                    <td>{uri.status}</td>
                                  </tr>
                                ))}
                            </tbody>
                            <tfoot>
                              <tr>
                                <th>Plan Name</th>
                                <th>Plan Description</th>
                                <th>Price</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Status</th>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionView;
