import { useState } from "react";
import UserApi from "../api/User";
import $ from "jquery";

const Login = () => {
  const [mobileno, setMobileno] = useState("");

  const handleSubmit = async () => {
    if (mobileno === "") {
      return alert("Please enter your number");
    } else if (mobileno.length < 10) {
      return alert("Please enter 10 digit number");
    } else {
      const user = {
        mobile_no: mobileno,
      };
      const result = await UserApi.MessageOfOtp(user);
      if (!result.ok) {
        return alert("Please try again later.");
      } else {
        try {
          if (result.data) {
            if (result.data.error) return alert(result.data.error[0]);
          }
          await localStorage.setItem("mobileNumber", mobileno);
          window.location = "/otp";
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  $("#title").text("Login - JamaUdhar");

  return (
    <body
      className="vertical-layout vertical-menu-modern 1-column navbar-floating footer-static bg-full-screen-image blank-page blank-page"
      data-open="click"
      data-menu="vertical-menu-modern"
      data-col="1-column"
    >
      <div className="app-content content">
        <div className="content-overlay"></div>
        <div className="header-navbar-shadow"></div>
        <div className="content-wrapper">
          <div className="content-header row"></div>
          <div className="content-body">
            <section
              className="row flexbox-container"
              style={{ backgroundColor: "#00706c" }}
            >
              <div className="col-xl-8 col-11 d-flex justify-content-center">
                <div
                  className="card bg-authentication rounded-0 mb-0"
                  style={{
                    paddingBottom: 50,
                    paddingTop: 10,
                    backgroundColor: "#00706c",
                  }}
                >
                  <div className="row m-0">
                    {/* <div className="col-lg-6 d-lg-block d-none text-center align-self-center px-1 py-0">
                      <img
                        src="../../../app-assets/images/pages/login.png"
                        alt="branding logo"
                      />
                    </div> */}
                    <div
                      className="col-lg-12 col-12 p-0"
                      style={{ backgroundColor: "#00706c" }}
                    >
                      <div
                        className="card rounded-0 mb-0 px-2"
                        style={{ backgroundColor: "#00706c" }}
                      >
                        <div
                          className="card-header"
                          style={{ justifyContent: "center" }}
                        >
                          <div className="card-title">
                            <img
                              src="../../../app-assets/images/logo/JU.png"
                              alt="png"
                              height="100"
                            />
                          </div>
                        </div>
                        <div
                          className="card-header pb-1"
                          style={{ justifyContent: "center" }}
                        >
                          <div className="card-title">
                            <h4
                              className="mb-0"
                              style={{
                                color: "white",
                              }}
                            >
                              Login
                            </h4>
                          </div>
                        </div>
                        <p className="px-2"></p>
                        <div className="card-content">
                          <div className="card-body pt-1">
                            <div className="form-label-group form-group position-relative has-icon-left">
                              <input
                                type="numeric"
                                className="form-control"
                                id="mobileno"
                                onChange={(e) => setMobileno(e.target.value)}
                                placeholder="Mobile Number"
                                maxLength="10"
                                required
                                style={{ height: 40, fontSize: 19 }}
                                inputMode="numeric"
                              />
                              <div className="form-control-position">
                                <i className="feather icon-user"></i>
                              </div>
                              <label for="mobileno">Mobile number</label>
                            </div>

                            {/*<div className="form-group d-flex justify-content-between align-items-center">*/}
                            {/*  <div className="text-left">*/}
                            {/*    <fieldset className="checkbox">*/}
                            {/*      <div className="vs-checkbox-con vs-checkbox-primary">*/}
                            {/*        <input type="checkbox" />*/}
                            {/*        <span className="vs-checkbox">*/}
                            {/*          <span className="vs-checkbox--check">*/}
                            {/*            <i className="vs-icon feather icon-check"></i>*/}
                            {/*          </span>*/}
                            {/*        </span>*/}
                            {/*        <span className="">Remember me</span>*/}
                            {/*      </div>*/}
                            {/*    </fieldset>*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                            <div
                              className="card-header pb-1"
                              style={{ justifyContent: "center" }}
                            >
                              <div className="card-title">
                                <h4 className="mb-0">
                                  <button
                                    type="button"
                                    className="btn btn-inline btn-center"
                                    style={{
                                      backgroundColor: "white",
                                      color: "#00706c",
                                    }}
                                    onClick={() => handleSubmit()}
                                  >
                                    <b>Next</b>
                                  </button>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 d-flex justify-content-center">
                <a target={"_blank"} href={"https://jamaudhar.in/terms-conditions.html"} style={{color:"white"}}> Terms & Conditions</a>&nbsp;&nbsp;&nbsp;
                <a target={"_blank"} href={"https://jamaudhar.in/privacy-policy.html"} style={{color:"white"}}> Privacy Policy</a>&nbsp;&nbsp;&nbsp;
                <a target={"_blank"} href={"https://jamaudhar.in/terms-conditions.html#refund"} style={{color:"white"}}>Refund/Cancellation Policy</a>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
};

export default Login;
