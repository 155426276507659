import Header from "./Header";
import { useEffect, useState } from "react";
import UserApi from "../api/User";
import Swal from "sweetalert2";
import $ from "jquery";

const ChoosePlan = (route) => {
  const [planList, setPlanList] = useState([]);
  const [secondaryList, setSecondaryList] = useState([]);
  const [user, setUser] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [total, setTotal] = useState("");
  // const [subscriptionplan_id, setSubscriptionplan_id] = useState();
  const [subscriptionplan_name, setSubscriptionplan_name] = useState();
  const userData = route.location.state.data;

  useEffect(() => {
    userExistOrNot();
    getPlanList();
    getSecondaryList();
  }, []);

  const userExistOrNot = async () => {
    try {
      const user = await localStorage.getItem("user");
      await setUser(JSON.parse(user));
      if (!user) {
        window.location = "/";
      }
    } catch (error) {}
  };

  const getPlanList = async () => {
    const result = await UserApi.getPlanList();

    if (!result.ok) {
      // alert("Please try again later");
      Swal.fire({
        title: "Jama Udhar",
        text: "Please try again later!",
        icon: "error",
        confirmButtonClass: "btn btn-primary",
        buttonsStyling: false,
      });
    } else {
      await setPlanList(result.data);
    }
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const displayRazorPay = async (orderDetail) => {
    const user = await localStorage.getItem("user");
    const userDetail = JSON.parse(user);

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      // alert("Razorpay SDK failed to load. Are you online?");
      Swal.fire({
        title: "Jama Udhar",
        text: "Razorpay SDK failed to load. Are you online?",
        icon: "error",
        confirmButtonClass: "btn btn-primary",
        buttonsStyling: false,
      });
      return;
    }

    const options = {
      key: "rzp_live_KiFX7wdUKtEu8U", // Enter the Key ID generated from the Dashboard
      amount: orderDetail.amount,
      currency: orderDetail.currency,
      name: userDetail.first_name + " " + userDetail.last_name,
      description: subscriptionplan_name,
      order_id: orderDetail.order_id,
      handler: async function (response) {
        const data = {
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        const result = await UserApi.getPaymentDetail(data);

        if (!result.ok) {
          // return alert("Please try again later");
          Swal.fire({
            title: "Jama Udhar",
            text: "Please try again later!",
            icon: "error",
            confirmButtonClass: "btn btn-primary",
            buttonsStyling: false,
          });
        } else {
          console.log(result.data);
          window.location = "/home";
        }
      },
      prefill: {
        name: userDetail.first_name + " " + userDetail.last_name,
        email: "xyz@xyz.com",
        contact: userDetail.mobile_no,
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#00776c",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const subscriptionCheckout = async (subscriptionDetail) => {
    const data = localStorage.getItem("user");
    const userDetail = JSON.parse(data);

    const SelectMobileNo = [];
    SelectMobileNo.push(userData.sub_mobile_no);

    const subscription = {
      subscriptionplan_id: subscriptionDetail.subscriptionplan_id,
      userid: userDetail.id,
      secondaryuserid: JSON.stringify(SelectMobileNo),
    };
    console.log(subscription);
    const result = await UserApi.subscriptionCheckout(subscription);
    console.log(result);
    if (!result.ok) {
      // return alert("Please try again later.");
      Swal.fire({
        title: "Jama Udhar",
        text: "Please try again later!",
        icon: "error",
        confirmButtonClass: "btn btn-primary",
        buttonsStyling: false,
      });
    } else {
      if (result.data.success) {
        await setSelectedList([]);
        await displayRazorPay(result.data.success);
        return;
      } else if (result.data.error) {
        Swal.fire({
          title: "Jama Udhar",
          text: result.data.error[0],
          icon: "error",
          confirmButtonClass: "btn btn-primary",
          buttonsStyling: false,
        });
        // return alert(result.data.error[0]);
      }
    }
  };

  const getSecondaryList = async () => {
    const results = await localStorage.getItem("user");
    const userDetail = JSON.parse(results);

    const users = {
      mobileno: userDetail.mobile_no,
    };
    const result = await UserApi.getSecondaryList(users);
    if (!result.ok) {
      // alert("Please try again later");
      Swal.fire({
        title: "Jama Udhar",
        text: "Please try again later!",
        icon: "error",
        confirmButtonClass: "btn btn-primary",
        buttonsStyling: false,
      });
    } else {
      await setSecondaryList(result.data);
    }
  };

  // const getPlanDetail = async (value) => {
  //   await setSubscriptionplan_name(value.plan_name);
  //   await setSubscriptionplan_id(value.subscriptionplan_id);
  //   await $(".priceclass").text(value.plan_price);
  //   $("#secondaryAccount" + user.id).attr("checked", true);
  //   $("#secondaryAccount" + user.id).attr("disabled", true);
  //   await getSelectedSecoundaryList(user);
  // };

  const getSelectedSecoundaryList = async (value) => {
    var IdArr = [];
    await setSelectedList([]);
    var totalPrice = 0;
    $('input[name="secondaryAccount"]:checked').each(async function () {
      IdArr.push(this.value);
      totalPrice = Number(totalPrice) + Number($("#price" + this.idno).text());
      await setTotal(totalPrice);
    });
    $("#total").text(totalPrice);
    await setSelectedList(IdArr);
  };

  $("#title").text("Choose Plan - JamaUdhar");
  console.log(userData);
  return (
    <>
      <Header />
      <div className="app-content content">
        <div className="content-overlay"></div>
        <div className="header-navbar-shadow"></div>
        <div className="content-wrapper">
          <div className="content-header row">
            <div className="content-header-left col-md-9 col-12 mb-2">
              <div className="row breadcrumbs-top">
                <div className="col-12">
                  &nbsp;
                  <h2 className="content-header-title  mb-0">Choose Plan</h2>
                  {/* <div className="breadcrumb-wrapper col-12">
                    <ol className="breadcrumb"></ol>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="content-body">
            <section className="page-users-view">
              <div className="row">
                {/* account start */}
                <div className={"col-12"}>
                  <div
                    className="card"
                    style={{
                      backgroundImage: "url('')",
                      background: "#00776c",
                      color: "white",
                    }}
                  >
                    <div
                      className="card-header"
                      style={{ justifyContent: "center" }}
                    >
                      <div className="card-title" style={{ color: "#ffcc58" }}>
                        {userData &&
                          userData.first_name + " " + userData.last_name}
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="users-view-image">
                          {/*<img src="../../../app-assets/images/portrait/small/avatar-s-12.jpg" className="users-avatar-shadow w-100 rounded mb-2 pr-2 ml-1" alt="avatar" />*/}
                        </div>
                        <div className="col-12 col-sm-9 col-md-6 col-lg-5">
                          <center>
                            <table>
                              {/*<tr>*/}
                              {/*  <td><h5 className="font-weight-bold" style={{"color":"white"}}>Name</h5></td>*/}
                              {/*  <td>*/}
                              {/*    /!*<h5 style={{"color":"#ffcc58"}}></h5>*!/*/}
                              {/*  </td>*/}
                              {/*</tr>*/}
                              <tr>
                                <td className="font-weight-bold">
                                  <h5
                                    className="font-weight-bold"
                                    style={{ color: "white" }}
                                  >
                                    Mobile No
                                  </h5>
                                </td>
                                <td>
                                  <h5
                                    style={{
                                      color: "#ffcc58",
                                      textAlign: "right",
                                    }}
                                  >
                                    {userData && userData.sub_mobile_no}
                                  </h5>
                                </td>
                              </tr>
                              <tr>
                                <td className="font-weight-bold">
                                  <h5
                                    className="font-weight-bold"
                                    style={{ color: "white" }}
                                  >
                                    {" "}
                                    Membership
                                  </h5>
                                </td>
                                <td>
                                  <h5
                                    style={{
                                      color: "#ffcc58",
                                      textAlign: "right",
                                    }}
                                  >
                                    {userData && userData.subscription_status}
                                  </h5>
                                </td>
                              </tr>
                            </table>
                          </center>
                        </div>
                        <div className="col-12 col-md-12 col-lg-5">
                          <center>
                            <table className="ml-0 ml-sm-0 ml-lg-0">
                              <tbody></tbody>
                            </table>
                          </center>
                        </div>
                        {/*<div className="col-12">*/}
                        {/*  <a href="app-user-edit.html" className="btn btn-primary mr-1 waves-effect waves-light"><i className="feather icon-edit-1" /> Edit</a>*/}
                        {/*  <button className="btn btn-outline-danger waves-effect waves-light"><i className="feather icon-trash-2" /> Delete</button>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </div>
                </div>
                {/* account end */}
              </div>
            </section>
            {/* <!-- Basic example and Profile cards section start --> */}
            <section id="basic-examples">
              <div className="row match-height">
                {planList &&
                  planList.map((uri, index) => (
                    <>
                      {uri.subscriptionplan_id !== 1 && (
                        <div className="col-xl-4 col-md-6 col-sm-12">
                          <div className="card">
                            <div className="card-content">
                              <div className="card-body">
                                <div
                                  style={{
                                    height: 120,
                                    backgroundColor: "#00776C",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    borderTopLeftRadius: 15,
                                    borderTopRightRadius: 15,
                                    flexDirection: "row",
                                  }}
                                >
                                  <div style={{ flexDirection: "column" }}>
                                    <h3
                                      style={{
                                        color: "white",
                                        textAlign: "center",
                                      }}
                                    >
                                      {uri.plan_name} <br />
                                      <del
                                        style={{
                                          color: "#BAC8D3",
                                          fontSize: 17,
                                          height: 20,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        ₹ {uri.old_plan_price}
                                      </del>{" "}
                                      ₹ {uri.plan_price}
                                    </h3>
                                  </div>
                                </div>

                                <hr className="my-1" />
                                <div
                                  className="d-flex justify-content-between mt-2"
                                  style={{ paddingLeft: 20, paddingRight: 20 }}
                                >
                                  <h5 style={{ color: "gray" }}>
                                    Validity upto
                                  </h5>
                                  <h4>{uri.validity_days} days</h4>
                                </div>
                                <div
                                  className="float-left"
                                  style={{
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    paddingTop: 15,
                                  }}
                                >
                                  <h5 style={{ color: "gray" }}>Description</h5>
                                  <h5>{uri.plan_description}</h5>
                                </div>
                              </div>
                            </div>
                            <div
                              className="card-btns"
                              style={{
                                justifyContent: "center",
                                display: "flex",
                                marginTop: 30,
                                paddingBottom: 30,
                              }}
                            >
                              <button
                                className="btn gradient-light-primary white"
                                // data-toggle="modal"
                                // data-target="#inlineForm"
                                onClick={() => subscriptionCheckout(uri)}
                              >
                                Pay Now
                              </button>

                              {/* modal */}
                              <div
                                class="modal fade text-left"
                                id="inlineForm"
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="myModalLabel33"
                                aria-hidden="true"
                              >
                                <div
                                  class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                                  role="document"
                                >
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <h4
                                        class="modal-title"
                                        id="myModalLabel33"
                                      >
                                        Choose Secondary Account
                                      </h4>
                                      <button
                                        type="button"
                                        class="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <form action="#">
                                      <div class="modal-body">
                                        <div className="table-responsive">
                                          <table className="table">
                                            <tbody>
                                              <tr>
                                                <th scope="row">
                                                  <fieldset>
                                                    <div className="vs-checkbox-con vs-checkbox-primary">
                                                      <input
                                                        name="secondaryAccount"
                                                        type="checkbox"
                                                        id={
                                                          "secondaryAccount" +
                                                          user.id
                                                        }
                                                        idno={user.id}
                                                        value={
                                                          user.sub_mobile_no
                                                        }
                                                        readOnly
                                                      />
                                                      <span className="vs-checkbox">
                                                        <span className="vs-checkbox--check">
                                                          <i className="vs-icon feather icon-check"></i>
                                                        </span>
                                                      </span>
                                                      <span className=""></span>
                                                    </div>
                                                  </fieldset>
                                                </th>
                                                <td>
                                                  {" "}
                                                  {user.first_name +
                                                    " " +
                                                    user.last_name}
                                                </td>
                                                <td>
                                                  ₹{" "}
                                                  <d
                                                    id={"price" + uri.id}
                                                    className="priceclass"
                                                  >
                                                    500
                                                  </d>
                                                </td>
                                              </tr>
                                              {secondaryList &&
                                                secondaryList.map(
                                                  (uri, index) => (
                                                    <tr>
                                                      <th scope="row">
                                                        <fieldset>
                                                          <div className="vs-checkbox-con vs-checkbox-primary">
                                                            <input
                                                              name="secondaryAccount"
                                                              id={
                                                                "secondaryAccount" +
                                                                uri.id
                                                              }
                                                              type="checkbox"
                                                              idno={uri.id}
                                                              onClick={() =>
                                                                getSelectedSecoundaryList(
                                                                  uri
                                                                )
                                                              }
                                                              value={
                                                                uri.sub_mobile_no
                                                              }
                                                            />
                                                            <span className="vs-checkbox">
                                                              <span className="vs-checkbox--check">
                                                                <i className="vs-icon feather icon-check"></i>
                                                              </span>
                                                            </span>
                                                            <span className=""></span>
                                                          </div>
                                                        </fieldset>
                                                      </th>
                                                      <td>
                                                        {" "}
                                                        {uri.first_name +
                                                          " " +
                                                          uri.last_name}
                                                      </td>
                                                      <td>
                                                        ₹{" "}
                                                        <d
                                                          id={"price" + uri.id}
                                                          className="priceclass"
                                                        >
                                                          500
                                                        </d>
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              <tr>
                                                <th scope="row"></th>
                                                <td
                                                  style={{ textAlign: "right" }}
                                                >
                                                  {" "}
                                                  Total :
                                                </td>
                                                <td>
                                                  ₹{" "}
                                                  <d
                                                    id="total"
                                                    className="priceclass"
                                                  >
                                                    500
                                                  </d>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <div class="modal-footer">
                                        <button
                                          type="button"
                                          class="btn btn-primary"
                                          data-dismiss="modal"
                                          onClick={() => subscriptionCheckout()}
                                        >
                                          Pay
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
              </div>
            </section>
            <a
              href="/home"
              className="btn btn-primary white"
              style={{ marginBottom: 25 }}
            >
              <i className="feather icon-chevron-left"></i> Back{" "}
            </a>
            {/* <!-- // Basic example and Profile cards section end -->*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChoosePlan;
