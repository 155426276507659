import Header from "./Header";
import { useEffect, useState } from "react";
import UserApi from "../api/User";
import $ from "jquery";
import Swal from "sweetalert2";

const ContactUs = () => {
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState();

  useEffect(() => {
    userExistOrNot();
  }, []);

  const userExistOrNot = async () => {
    try {
      const user = await localStorage.getItem("user");
      if (!user) {
        window.location = "/";
      }
    } catch (error) {}
  };

  const addHelpQuery = async () => {
    const results = await localStorage.getItem("user");
    const userDetail = JSON.parse(results);

    const users = {
      userid: userDetail.id,
      subject: subject,
      description: description,
      files: files,
    };
    const result = await UserApi.addHelpQuery(users);

    if (!result.ok) {
      Swal.fire({
        title: "Jama Udhar",
        text: "Please Try Again Later!",
        icon: "error",
        confirmButtonClass: "btn btn-primary",
        buttonsStyling: false,
      });
    } else {
      await setSubject("");
      await setDescription("");
      await setFiles();
      Swal.fire({
        title: "Jama Udhar",
        text: "Your query is successfully submitted.",
        icon: "success",
        confirmButtonClass: "btn btn-primary",
        buttonsStyling: false,
      });
    }
  };

  $("#title").text("Any Query - JamaUdhar");

  return (
    <>
      <Header />
      <div className="app-content content">
        <div className="content-overlay"></div>
        <div className="header-navbar-shadow"></div>
        <div className="content-wrapper">
          <div className="content-header row">
            <div className="content-header-left col-md-9 col-12 mb-2">
              <div className="row breadcrumbs-top">
                <div className="col-12">
                  <h2 className="content-header-title float-left mb-0">
                    Any Query
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="content-body">
            <div className="offset-sm-1 col-sm col-sm-10">
              <div className="card" style={{ height: "419px" }}>
                <div className="card-header">
                  <h4 className="card-title">Query</h4>
                </div>
                <div className="card-content">
                  <div
                    className="card-body"
                    style={{ backgroundColor: "white" }}
                  >
                    <div className="offset-sm-1 col-sm col-sm-10">
                      <form className="form form-horizontal">
                        <div className="form-body">
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group row">
                                <div className="col-md-3">
                                  <span>Subject</span>
                                </div>
                                <div className="col-md-8">
                                  <div className="position-relative has-icon-left">
                                    <input
                                      type="text"
                                      id="fname-icon"
                                      className="form-control"
                                      name="fname-icon"
                                      placeholder="Subject"
                                      onChange={(e) =>
                                        setSubject(e.target.value)
                                      }
                                    />
                                    <div className="form-control-position">
                                      <i className="feather icon-help-circle"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group row">
                                <div className="col-md-3">
                                  <span>Discription</span>
                                </div>
                                <div className="col-md-8">
                                  <div className="position-relative has-icon-left">
                                    <textarea
                                      className="form-control"
                                      placeholder="Discription"
                                      rows="5"
                                      onChange={(e) =>
                                        setDescription(e.target.value)
                                      }
                                    >
                                      {" "}
                                    </textarea>
                                    <div className="form-control-position">
                                      <i className="feather icon-file-text"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group row">
                                <div className="col-md-3">
                                  <span>Upload Image</span>
                                </div>
                                <div className="col-md-8">
                                  <div className="position-relative has-icon-left">
                                    <input
                                      type="file"
                                      multiple
                                      id="upload_file"
                                      onChange={(e) => {
                                        setFiles(e.target.files);
                                      }}
                                      className="form-control"
                                      placeholder="Upload An image"
                                    />
                                    <div className="form-control-position">
                                      <i className="feather icon-file"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-8 offset-md-4">
                              <button
                                type="button"
                                onClick={() => addHelpQuery()}
                                className="btn btn-primary mr-1 mb-1 waves-effect waves-light"
                              >
                                Submit
                              </button>
                              <button
                                type="reset"
                                className="btn btn-outline-warning mr-1 mb-1 waves-effect waves-light"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
