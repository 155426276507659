import $ from "jquery";
import Header from "./Header";

const ContactDetail = () => {
  $("#title").text("Contact Us - JamaUdhar");

  return (
    <>
      <Header />
      <div className="app-content content">
        <div className="content-overlay"></div>
        <div className="header-navbar-shadow"></div>
        <div className="content-wrapper">
          <div className="content-header row">
            <div className="content-header-left col-md-9 col-12 mb-2">
              <div className="row breadcrumbs-top">
                <div className="col-12">
                  <h2 className="content-header-title float-left mb-0">
                    Contact Us
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="content-body">
            <div className="offset-sm-1 col-sm col-sm-10">
              <div className="card" style={{ height: "100%" }}>
                {/* <div className="card-header">
                  <h4 className="card-title">Query</h4>
                </div> */}
                <div className="card-content">
                  <div
                    className="card-body"
                    style={{ backgroundColor: "white" }}
                  >
                    <div className="offset-sm-1 col-sm col-sm-10">
                      <form className="form form-horizontal">
                        <div className="card">
                          <div className="card-header">
                            <h4>About</h4>
                            {/*<i className="feather icon-more-horizontal cursor-pointer"></i>*/}
                          </div>
                          <div className="card-body">
                            <p>
                              303, Nakshatra Heights 150 Feet Ring Road, Opp
                              Raiya Telephone Exchange Besides Water Tank,
                              Gujarat 360005
                            </p>

                            <div className="mt-1">
                              <h6 className="mb-0">Mobile Number:</h6>
                              <p>90900 50095</p>
                            </div>

                            <div className="mt-1">
                              <h6 className="mb-0">Lives:</h6>
                              <p>Rajkot, India</p>
                            </div>
                            <div className="mt-1">
                              <h6 className="mb-0">Email:</h6>
                              <p>jamaudhardev@gmail.com</p>
                            </div>
                            <div className="mt-1">
                              <h6 className="mb-0">Website:</h6>
                              <p>www.jamaudhar.in</p>
                            </div>
                            <div className="mt-1">
                              <a
                                style={{ color: "white" }}
                                type="button"
                                className="btn btn-sm btn-icon btn-primary mr-25 p-25 waves-effect waves-light"
                              >
                                <i className="feather icon-facebook"></i>
                              </a>
                              <a
                                style={{ color: "white" }}
                                type="button"
                                href="http://api.whatsapp.com/send?phone=+919090050095"
                                className="btn btn-sm btn-icon btn-primary mr-25 p-25 waves-effect waves-light"
                              >
                                <i className="fa fa-whatsapp"></i>
                              </a>
                              {/* <button
                                type="button"
                                className="btn btn-sm btn-icon btn-primary p-25 waves-effect waves-light"
                              >
                                <i className="feather icon-instagram"></i>
                              </button> */}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactDetail;
