import client from "./Client";

const endPoint = "/api/User/storeUser";
const editEndPoint = "/api/User/editUser";
const signInEndPoint = "/api/User/storeUser";
const checkOTPEndPoint = "/api/User/checkOTP";
const MessageOTPEndPoint = "/api/User/messageApi";
const SubscriptionListendPoint = "/api/User/GetSubscription";
const invoiceEndPoint = "/api/User/invoiceDetail";
const getSecondaryListEndPoint = "/api/User/getSecondaryList";
const getPlanListEndPoint = "/api/User/GetSubscriptionPlan";
const getPaymentDetailListEndPoint = "/api/User/PaymentSuccess";
const setSubscriptionCheckout = "/api/User/SubscriptionCheckout";
const HelpQueryEndPoint = "/api/User/HelpQuery";
const deleteEndPoint = "/api/User/ChangeStatus";

const signIn = (user) => {
  const data = new FormData();
  data.append("type", "LogIn");
  data.append("mobile_no", user.mobileno);
  //   data.append("device_mac", user.device_mac);
  //   data.append("device_name", user.device_name);
  //   data.append("device_platform", user.device_platform);
  //   data.append("device_os", user.device_os);

  return client.post(signInEndPoint, data);
};

const MessageOfOtp = (user) => {
  const data = new FormData();
  data.append("sub_mobile_no", user.mobile_no);

  return client.post(MessageOTPEndPoint, data);
};

const checkOTP = (user) => {
  const data = new FormData();
  data.append("mobile_no", user.mobile_no);
  data.append("otp", user.otp);

  return client.post(checkOTPEndPoint, data);
};

const addUser = (user) => {
  const data = new FormData();
  data.append("type", "SignUp");
  data.append("first_name", user.firstname);
  data.append("last_name", user.lastname);
  data.append("mobile_no", user.mobileno);
  data.append("city", user.city);
  data.append("sub_mobile_no", user.submobileno);
  data.append("notification_token", user.notification_token);
  data.append("gst_no", user.gst_no);
  data.append("pancard_no", user.pancard_no);
  data.append("TnC", user.TnC);

  return client.post(endPoint, data);
};

const editUser = (user) => {
  const data = new FormData();
  data.append("user_id", user.user_id);
  data.append("TnC", user.TnC);

  return client.post(editEndPoint, data);
};

const getSubscriptionList = (user) => {
  const data = new FormData();
  data.append("mobile_no", user.mobile_no);
  data.append("status", user.status);

  return client.post(SubscriptionListendPoint, data);
};

const getInvoice = (invoice) => {
  const data = new FormData();
  data.append("payment_id", invoice.payment_id);

  return client.post(invoiceEndPoint, data);
};

const addHelpQuery = (user) => {
  const data = new FormData();
  data.append("userid", user.userid);
  data.append("subject", user.subject);
  data.append("discription", user.description);

  if (user.files) {
    data.append("filescount", user.files.length);

    for (let i = 0; i < user.files.length; i++) {
      data.append("files" + i, user.files[i]);
    }
  }

  return client.post(HelpQueryEndPoint, data);
};

const getSecondaryList = (user) => {
  const data = new FormData();
  data.append("mobile_no", user.mobileno);

  return client.post(getSecondaryListEndPoint, data);
};

const getPlanList = () => {
  const data = new FormData();

  return client.post(getPlanListEndPoint, data);
};

const subscriptionCheckout = (subscription) => {
  const data = new FormData();
  data.append("subscriptionplan_id", subscription.subscriptionplan_id);
  data.append("userid", subscription.userid);
  data.append("secondaryuserid", subscription.secondaryuserid);

  return client.post(setSubscriptionCheckout, data);
};

const getPaymentDetail = (paymentDetail) => {
  const data = new FormData();
  data.append("razorpay_payment_id", paymentDetail.razorpayPaymentId);
  data.append("razorpay_order_id", paymentDetail.razorpayOrderId);
  data.append("razorpay_signature", paymentDetail.razorpaySignature);

  return client.post(getPaymentDetailListEndPoint, data);
};

const changeStatus = (user) => {
  const data = new FormData();
  data.append("mobile_no", user.mobile_no);
  data.append("user_id", user.user_id);
  data.append("status", user.status);
  data.append("primary_mobile_no", user.primary_mobile_no);

  return client.post(deleteEndPoint, data);
};

export default {
  signIn,
  MessageOfOtp,
  checkOTP,
  addUser,
  getSubscriptionList,
  getSecondaryList,
  getPlanList,
  subscriptionCheckout,
  getPaymentDetail,
  addHelpQuery,
  changeStatus,
  getInvoice,
  editUser,
};
