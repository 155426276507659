import { useEffect, useState } from "react";
import Header from "./Header";
import UserApi from "../api/User";
import Swal from "sweetalert2";
import $ from "jquery";
import { Link } from "react-router-dom";

const SecondaryList = () => {
  const [secondaryList, setSecondaryList] = useState([]);
  // const [firstname, setFirstName] = useState("");
  // const [lastname, setLastname] = useState("");
  // const [mobileno, setMobileNo] = useState("");
  // const [city, setCity] = useState("");
  const [planList, setPlanList] = useState([]);
  const [secondaryUserMobile, setSecondaryUserMobile] = useState([]);
  const [secondaryUserID, setSecondaryUserID] = useState();

  useEffect(() => {
    userExistOrNot();
    getSecondaryList();
    getPlanList();
  }, []);
  console.log(secondaryList);
  const userExistOrNot = async () => {
    try {
      const user = await localStorage.getItem("user");
      if (!user) {
        window.location = "/";
      }
    } catch (error) {}
  };

  const getSecondaryList = async () => {
    const results = await localStorage.getItem("user");
    const userDetail = JSON.parse(results);

    const users = {
      mobileno: userDetail.mobile_no,
    };
    const result = await UserApi.getSecondaryList(users);
    if (!result.ok) {
      alert("Please try again later");
    } else {
      setSecondaryList(result.data);
    }
  };

  // const addSecondaryAccount = async () => {
  //   if (firstname === "" || lastname === "" || mobileno === "") {
  //     return alert("All the fields are required");
  //   } else if (mobileno.length < 10) {
  //     return alert("Enter 10 digit mobile no.");
  //   }

  //   const results = await localStorage.getItem("user");
  //   const userDetail = JSON.parse(results);

  //   const users = {
  //     firstname,
  //     lastname,
  //     mobileno: userDetail.mobile_no,
  //     submobileno: mobileno,
  //     city: city,
  //   };
  //   const result = await UserApi.addUser(users);
  //   if (!result.ok) {
  //     return alert("Could not add user. Please try again");
  //   } else {
  //     if (result.data) {
  //       if (result.data.error) {
  //         return alert(result.data.error[0]);
  //       }
  //     }
  //     await getSecondaryList();
  //   }
  // };

  const deleteSecondaryAccount = async (userData, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't to " + status + " this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: false,
    }).then(async function (result) {
      if (result.value) {
        const userDetail = {
          mobile_no: userData.sub_mobile_no,
          user_id: userData.id,
          status: status,
          primary_mobile_no: userData.mobile_no,
        };

        const result = await UserApi.changeStatus(userDetail);
        console.log(result);
        if (result.data === "success") {
          window.location = "/secondaryList";
          // loadSecondayAccount();
        } else if (result.data.error) {
          return alert(result.data.error[0]);
        }
      }
    });
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const displayRazorPay = async (orderDetail, subscriptionDetail) => {
    const user = await localStorage.getItem("user");
    const userDetail = JSON.parse(user);

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      // alert("Razorpay SDK failed to load. Are you online?");
      Swal.fire({
        title: "Jama Udhar",
        text: "Razorpay SDK failed to load. Are you online?",
        icon: "error",
        confirmButtonClass: "btn btn-primary",
        buttonsStyling: false,
      });
      return;
    }

    const options = {
      key: "rzp_live_KiFX7wdUKtEu8U", // Enter the Key ID generated from the Dashboard
      amount: orderDetail.amount,
      currency: orderDetail.currency,
      name: userDetail.first_name + " " + userDetail.last_name,
      description: subscriptionDetail.subscriptionplan_name,
      order_id: orderDetail.order_id,
      handler: async function (response) {
        const data = {
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        const result = await UserApi.getPaymentDetail(data);

        if (!result.ok) {
          // return alert("Please try again later");
          Swal.fire({
            title: "Jama Udhar",
            text: "Please try again later!",
            icon: "error",
            confirmButtonClass: "btn btn-primary",
            buttonsStyling: false,
          });
        } else {
          console.log(result.data);
          window.location = "/secondaryList";
        }
      },
      prefill: {
        name: userDetail.first_name + " " + userDetail.last_name,
        email: "xyz@xyz.com",
        contact: userDetail.mobile_no,
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#00776c",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const subscriptionCheckout = async (subscriptionDetail) => {
    const data = localStorage.getItem("user");
    const userDetail = JSON.parse(data);

    const subscription = {
      subscriptionplan_id: subscriptionDetail.subscriptionplan_id,
      userid: userDetail.id,
      secondaryuserid: JSON.stringify(secondaryUserMobile),
    };
    const result = await UserApi.subscriptionCheckout(subscription);
    console.log(result);
    if (!result.ok) {
      // return alert("Please try again later.");
      Swal.fire({
        title: "Jama Udhar",
        text: "Please try again later!",
        icon: "error",
        confirmButtonClass: "btn btn-primary",
        buttonsStyling: false,
      });
    } else {
      if (result.data.success) {
        await setSecondaryUserMobile([]);
        await setSecondaryUserID("");
        await displayRazorPay(result.data.success, subscriptionDetail);
        return;
      } else if (result.data.error) {
        Swal.fire({
          title: "Jama Udhar",
          text: result.data.error[0],
          icon: "error",
          confirmButtonClass: "btn btn-primary",
          buttonsStyling: false,
        });
        // return alert(result.data.error[0]);
      }
    }
  };

  const getPlanList = async () => {
    const result = await UserApi.getPlanList();

    if (!result.ok) {
      // alert("Please try again later");
      Swal.fire({
        title: "Jama Udhar",
        text: "Please try again later!",
        icon: "error",
        confirmButtonClass: "btn btn-primary",
        buttonsStyling: false,
      });
    } else {
      await setPlanList(result.data);
    }
  };

  $("#title").text("Secondary Accounts - JamaUdhar");
  console.log(secondaryList);
  return (
    <>
      <Header />
      <div className="app-content content">
        <div className="content-overlay"></div>
        <div className="header-navbar-shadow"></div>
        <div className="content-wrapper">
          <div className="content-header row">
            <div className="content-header-left col-md-9 col-12 mb-2">
              <div className="row breadcrumbs-top">
                <div className="col-12">
                  <h2 className="content-header-title float-left mb-0">
                    Secondary Accounts
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="content-body">
            <section id="basic-datatable">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Accounts</h4>
                    </div>
                    <div
                      className="col-md-8 offset-md-4"
                      style={{ marginLeft: 0, marginTop: 15 }}
                    >
                      <Link to="/addSecondary">
                        <button
                          type="button"
                          class="btn btn-outline-success"
                          // data-toggle="modal"
                          // data-target="#inlineForm"
                        >
                          Add Account
                        </button>
                      </Link>

                      {/* <div
                        class="modal fade text-left"
                        id="inlineForm"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="myModalLabel33"
                        aria-hidden="true"
                      >
                        <div
                          class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                          role="document"
                        >
                          <div class="modal-content">
                            <div class="modal-header">
                              <h4 class="modal-title" id="myModalLabel33">
                                Add Secondary Account
                              </h4>
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <form action="#">
                              <div class="modal-body">
                                <label>First Name: </label>
                                <div class="form-group">
                                  <input
                                    type="text"
                                    placeholder="First Name"
                                    class="form-control"
                                    maxLength="15"
                                    onChange={(e) =>
                                      setFirstName(e.target.value)
                                    }
                                    required
                                  />
                                </div>

                                <label>Last Name: </label>
                                <div class="form-group">
                                  <input
                                    type="text"
                                    placeholder="Last Name"
                                    class="form-control"
                                    maxLength="15"
                                    onChange={(e) =>
                                      setLastname(e.target.value)
                                    }
                                    required
                                  />
                                </div>

                                <label>Mobile Number: </label>
                                <div class="form-group">
                                  <input
                                    type="text"
                                    placeholder="Mobile Number"
                                    class="form-control"
                                    maxLength="10"
                                    onChange={(e) =>
                                      setMobileNo(e.target.value)
                                    }
                                    required
                                  />
                                </div>
                                <label>City: </label>
                                <div class="form-group">
                                  <input
                                    type="text"
                                    placeholder="City"
                                    class="form-control"
                                    onChange={(e) => setCity(e.target.value)}
                                    required
                                  />
                                </div>
                              </div>
                              <div class="modal-footer">
                                <button
                                  type="button"
                                  class="btn btn-primary"
                                  data-dismiss="modal"
                                  onClick={() => addSecondaryAccount()}
                                >
                                  Sign Up
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div> */}

                      <div
                        class="modal fade"
                        id="paymentModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalScrollableTitle"
                        aria-hidden="true"
                      >
                        <div
                          class="modal-dialog modal-dialog-scrollable"
                          role="document"
                        >
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5
                                class="modal-title"
                                id="exampleModalScrollableTitle"
                              >
                                Choose Plan
                              </h5>
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <section id="basic-examples">
                                <div className="row match-height">
                                  {planList &&
                                    planList.map((uri, index) => (
                                      <>
                                        {uri.subscriptionplan_id !== 1 && (
                                          <div className="col-xl-6 col-md-12 col-sm-12">
                                            <div className="card">
                                              <div className="card-content">
                                                <div className="card-body">
                                                  <div
                                                    style={{
                                                      height: 120,
                                                      backgroundColor:
                                                        "#00776C",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                      display: "flex",
                                                      borderTopLeftRadius: 15,
                                                      borderTopRightRadius: 15,
                                                      flexDirection: "row",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        flexDirection: "column",
                                                      }}
                                                    >
                                                      <h3
                                                        style={{
                                                          color: "white",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {uri.plan_name} <br />₹{" "}
                                                        {uri.plan_price}
                                                      </h3>
                                                    </div>
                                                  </div>

                                                  <hr className="my-1" />
                                                  <div
                                                    className="d-flex justify-content-between mt-2"
                                                    style={{
                                                      paddingLeft: 20,
                                                      paddingRight: 20,
                                                    }}
                                                  >
                                                    <h5
                                                      style={{
                                                        color: "gray",
                                                      }}
                                                    >
                                                      Validity upto
                                                    </h5>
                                                    <h4>
                                                      {uri.validity_days} days
                                                    </h4>
                                                  </div>
                                                  <div
                                                    className="float-left"
                                                    style={{
                                                      paddingLeft: 20,
                                                      paddingRight: 20,
                                                      paddingTop: 15,
                                                    }}
                                                  >
                                                    <h5
                                                      style={{
                                                        color: "gray",
                                                      }}
                                                    >
                                                      Description
                                                    </h5>
                                                    <h5>
                                                      {uri.plan_description}
                                                    </h5>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="card-btns"
                                                style={{
                                                  justifyContent: "center",
                                                  display: "flex",
                                                  marginTop: 30,
                                                  paddingBottom: 30,
                                                }}
                                              >
                                                <button
                                                  className="btn gradient-light-primary white"
                                                  onClick={() =>
                                                    subscriptionCheckout(uri)
                                                  }
                                                >
                                                  Pay Now
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    ))}
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="content-body" style={{ paddingTop: 25 }}>
                      {/* <!-- Basic example and Profile cards section start --> */}
                      <section id="basic-examples">
                        <div className="row match-height">
                          {secondaryList &&
                            secondaryList.map((value, index) => (
                              <div className="col-xl-4 col-md-6 col-sm-12">
                                <div
                                  className="card"
                                  style={{
                                    marginLeft: 15,
                                    marginRight: 15,
                                  }}
                                >
                                  <div className="card-content">
                                    <div className="card-body">
                                      <div
                                        style={{
                                          height: 50,
                                          backgroundColor: "#00776C",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          display: "flex",
                                          borderTopLeftRadius: 15,
                                          borderTopRightRadius: 15,
                                        }}
                                      >
                                        <h3 style={{ color: "white" }}>
                                          {" "}
                                          {value.first_name +
                                            " " +
                                            value.last_name}
                                        </h3>
                                      </div>
                                      {/* <h3
                                        className="mt-1"
                                        style={{
                                          textAlign: "center",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {value.first_name != 0 &&
                                          "₹" + value.first_name}
                                      </h3> */}
                                      <hr className="my-1" />
                                      <div
                                        className="d-flex justify-content-between "
                                        style={{
                                          paddingLeft: 20,
                                          paddingRight: 20,
                                        }}
                                      >
                                        <h5 style={{ color: "gray" }}>
                                          Mobile No.
                                        </h5>
                                        <h4>{value.sub_mobile_no}</h4>
                                      </div>
                                      {/* <div
                                        className="d-flex justify-content-between"
                                        style={{
                                          paddingLeft: 20,
                                          paddingRight: 20,
                                        }}
                                      >
                                        <h5 style={{ color: "gray" }}>City</h5>
                                        <h4>{value.city}</h4>
                                      </div> */}
                                      <div
                                        className="d-flex justify-content-between"
                                        style={{
                                          paddingLeft: 20,
                                          paddingRight: 20,
                                        }}
                                      >
                                        <h5 style={{ color: "gray" }}>
                                          Membership Status
                                        </h5>
                                        <h4>{value.subscription_status}</h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="card-btns d-flex justify-content-center mt-2"
                                    style={{ marginLeft: 15, marginRight: 15 }}
                                  >
                                    {value.subscription_status === "Expired" &&
                                      value.status === "Active" && (
                                        <button
                                          class="btn gradient-light-primary text-white"
                                          style={{
                                            width: "50%",
                                            color: "white",
                                          }}
                                        >
                                          <Link
                                            to={{
                                              pathname: "/choosePlan",
                                              state: { data: value },
                                            }}
                                            style={{ color: "white" }}
                                          >
                                            Renew Plan
                                          </Link>
                                        </button>
                                      )}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    {value.status === "Active" && (
                                      <button
                                        onClick={() =>
                                          deleteSecondaryAccount(
                                            value,
                                            "Deactive"
                                          )
                                        }
                                        class="btn btn-outline-danger"
                                        style={{ width: "50%" }}
                                      >
                                        Deactive
                                      </button>
                                    )}
                                    {value.status === "Deactive" && (
                                      <button
                                        onClick={() =>
                                          deleteSecondaryAccount(
                                            value,
                                            "Active"
                                          )
                                        }
                                        class="btn btn-outline-primary"
                                        style={{ width: "50%" }}
                                      >
                                        Active
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </section>
                      {/* <!-- // Basic example and Profile cards section end -->*/}
                    </div>

                    {/* <div className="card-content">
                      <div className="card-body card-dashboard">
                        <p className="card-text"></p>
                        <div className="table-responsive">
                          <table
                            id="datatable"
                            className="table zero-configuration"
                          >
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Mobile No</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {secondaryList &&
                                secondaryList.map((uri, index) => (
                                  <tr>
                                    <td>
                                      {uri.first_name + " " + uri.last_name}
                                    </td>
                                    <td>{uri.sub_mobile_no}</td>
                                    <td>{uri.status}</td>
                                    <td>
<<<<<<< Updated upstream
=======
<<<<<<< HEAD
<<<<<<< HEAD
                                      {uri.subscription_status == "Expired" &&
                                        uri.status == "Active" && (
                                          <div className={"fonticon-container"}>
                                            <a
                                              data-toggle="modal"
                                              data-target="#paymentModal"
                                              className={"fonticon-wrap"}
                                              onClick={async () => {
                                                await setSecondaryUserID(
                                                  uri.id
                                                );
                                                await setSecondaryUserMobile([
                                                  uri.sub_mobile_no,
                                                ]);
                                              }}
                                            >
                                              <i
                                                className="fa fa-money"
                                                style={{ color: "green" }}
                                              ></i>
                                            </a>
                                          </div>
                                        )}
=======
=======
>>>>>>> 5a287a62127534ccedbbf42a8a839f39d12b6e44
>>>>>>> Stashed changes
                                      {(uri.subscription_status == "Expired" && uri.status == "Active") && (
                                        <div className={"fonticon-container"}>
                                          <a
                                            data-toggle="modal"
                                            data-target="#paymentModal"
                                            className={"fonticon-wrap"}
                                            onClick={async () => {
                                              await setSecondaryUserID(uri.id);
                                              await setSecondaryUserMobile([
                                                uri.sub_mobile_no,
                                              ]);
                                            }}
                                          >
                                            <i
                                              className="fa fa-money"
                                              style={{ color: "green" }}
                                            ></i>
                                          </a>
                                        </div>
                                      )}
<<<<<<< Updated upstream
=======
>>>>>>> 5a287a62127534ccedbbf42a8a839f39d12b6e44
>>>>>>> Stashed changes

                                      {uri.status == "Active" && (
                                        <div className={"fonticon-container"}>
                                          <a
                                            onClick={() =>
                                              deleteSecondaryAccount(
                                                uri,
                                                "Deactive"
                                              )
                                            }
                                            className={"fonticon-wrap"}
                                          >
                                            <i
                                              className="fa fa-times"
                                              style={{ color: "red" }}
                                            ></i>
                                          </a>
                                        </div>
                                      )}

                                      {uri.status == "Deactive" && (
                                        <div className={"fonticon-container"}>
                                          <a
                                            onClick={() =>
                                              deleteSecondaryAccount(
                                                uri,
                                                "Active"
                                              )
                                            }
                                            className={"fonticon-wrap"}
                                          >
                                            <i
                                              className="fa fa-check"
                                              style={{ color: "green" }}
                                            ></i>
                                          </a>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                            <tfoot>
                              <tr>
                                <th>Name</th>
                                <th>Mobile No</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
export default SecondaryList;
