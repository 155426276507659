import $ from "jquery";
import { useState } from "react";
import Header from "./Header";
import UserApi from "../api/User";
import { useHistory } from "react-router-dom";
import JWT from "jwt-decode";
import Swal from "sweetalert2";

const AddSecondary = () => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastname] = useState("");
  const [mobileno, setMobileNo] = useState("");
  const [checked, setChecked] = useState(false);
  const [confirmMobileno, setConfirmMobileNo] = useState("");
  const [city, setCity] = useState("");
  const history = useHistory();

  const addSecondaryAccount = async () => {
    if (!checked) {
      Swal.fire({
        title: "Jama Udhar",
        text: "Please accept terms & conditions.",
        icon: "error",
        confirmButtonClass: "btn btn-primary",
        buttonsStyling: false,
      });
      return;
    } else if (
      firstname === "" ||
      lastname === "" ||
      mobileno === "" ||
      confirmMobileno === "" ||
      city === ""
    ) {
      return alert("All the fields are required");
    } else if (mobileno !== confirmMobileno) {
      return alert("Both mobile number dosen't match!");
    } else if (mobileno.length < 10) {
      return alert("Enter 10 digit mobile no.");
    }

    const results = await localStorage.getItem("user");
    const userDetail = JSON.parse(results);

    const users = {
      firstname,
      lastname,
      mobileno: userDetail.mobile_no,
      submobileno: mobileno,
      city: city,
      TnC: "Yes",
    };
    const result = await UserApi.addUser(users);
    if (!result.ok) {
      return alert("Could not add user. Please try again");
    } else {
      if (result.data) {
        if (result.data.error) {
          return alert(result.data.error[0]);
        }
      }
      history.push("/choosePlan", { data: JWT(result.data) });
    }
  };

  $("#title").text("Add Secondary Accounts - JamaUdhar");

  return (
    <>
      <Header />
      <div className="app-content content">
        <div className="content-overlay"></div>
        <div className="header-navbar-shadow"></div>
        <div className="content-wrapper">
          <div className="content-header row">
            <div className="content-header-left col-md-9 col-12 mb-2">
              <div className="row breadcrumbs-top">
                <div className="col-12">
                  <h2 className="content-header-title float-left mb-0">
                    Add Secondary Account
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="content-body">
            <div className="offset-sm-1 col-sm col-sm-10">
              <div className="card" style={{ height: "auto" }}>
                {/* <div className="card-header">
                  <h4 className="card-title">Query</h4>
                </div> */}
                <div className="card-content">
                  <div
                    className="card-body"
                    style={{ backgroundColor: "white" }}
                  >
                    <div className="offset-sm-1 col-sm col-sm-10">
                      <form className="form form-horizontal">
                        <form action="#">
                          <div class="modal-body">
                            <label>First Name: </label>
                            <div class="form-group">
                              <input
                                type="text"
                                placeholder="First Name"
                                class="form-control"
                                maxLength="15"
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                              />
                            </div>

                            <label>Last Name: </label>
                            <div class="form-group">
                              <input
                                type="text"
                                placeholder="Last Name"
                                class="form-control"
                                maxLength="15"
                                onChange={(e) => setLastname(e.target.value)}
                                required
                              />
                            </div>

                            <label>Mobile Number: </label>
                            <div class="form-group">
                              <input
                                type="text"
                                placeholder="Mobile Number"
                                class="form-control"
                                maxLength="10"
                                onChange={(e) => setMobileNo(e.target.value)}
                                required
                              />
                            </div>
                            <label>Confirm Mobile Number: </label>
                            <div class="form-group">
                              <input
                                type="text"
                                placeholder="Mobile Number"
                                class="form-control"
                                maxLength="10"
                                onChange={(e) =>
                                  setConfirmMobileNo(e.target.value)
                                }
                                required
                              />
                            </div>
                            <label>City: </label>
                            <div class="form-group">
                              <input
                                type="text"
                                placeholder="City"
                                class="form-control"
                                onChange={(e) => setCity(e.target.value)}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <div className="col-12">
                                <fieldset className="checkbox">
                                  <div className="vs-checkbox-con vs-checkbox-primary">
                                    <input
                                      type="checkbox"
                                      defaultChecked={checked}
                                      onChange={() => setChecked(!checked)}
                                    />
                                    <span className="vs-checkbox">
                                      <span className="vs-checkbox--check">
                                        <i className="vs-icon feather icon-check"></i>
                                      </span>
                                    </span>
                                    <span className="">
                                      {" "}
                                      I accept the terms & conditions.
                                    </span>
                                  </div>
                                </fieldset>
                              </div>
                            </div>
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-primary"
                              data-dismiss="modal"
                              onClick={() => addSecondaryAccount()}
                            >
                              Add Account
                            </button>
                          </div>
                        </form>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSecondary;
