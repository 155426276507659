import "./App.css";
import Login from "./components/Login";
import Register from "./components/Register";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/Home";
import ChoosePlan from "./components/ChoosePlan";
import SubscriptionView from "./components/SubscriptionView";
import SecondaryList from "./components/SecondaryList";
import ContactUs from "./components/ContactUs";
import Otp from "./components/Otp";
import ContactDetail from "./components/ContactDetail";
import AddSecondary from "./components/AddSecondary";
import Invoice from "./components/Invoice";
import LogOut from "./components/LogOut";
import TermNCondition from "./components/TermNCondition";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/otp" component={Otp} />
        <Route path="/register" component={Register} />
        <Route path="/home" component={Home} />
        <Route path="/choosePlan" component={ChoosePlan} />
        <Route path="/membershipDetail" component={SubscriptionView} />
        <Route path="/secondaryList" component={SecondaryList} />
        <Route path="/ContactUs" component={ContactUs} />
        <Route path="/ContactDetail" component={ContactDetail} />
        <Route path="/addSecondary" component={AddSecondary} />
        <Route path="/termNCondition" component={TermNCondition} />
        <Route path="/Invoice" component={Invoice} />
        <Route path="/logOut" component={LogOut} />
      </Switch>
    </Router>
  );
}

export default App;
